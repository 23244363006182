/**
 * @license
 * MyFonts Webfont Build ID 4722881, 2022-04-20T22:18:44-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: HelveticaNowDisplay-Bd by Monotype
 * URL: https://www.myfonts.com/fonts/mti/helvetica-now/display-bold/
 * Copyright: Copyright &amp;#x00A9; 2019-2020 Monotype Imaging Inc. All rights reserved.
 * 
 * 
 * 
 * © 2022 MyFonts Inc
*/

@font-face {
    font-family: "Helvetica Now Display";
    src: url("/public/fonts/Monotype - Helvetica Now Display.otf") format("truetype");
}

@font-face {
    font-family: "Helvetica Now Display Medium";
    src: url("/public/fonts/Monotype - Helvetica Now Display Medium.otf") format("truetype");
}

@font-face {
    font-family: "Helvetica Now Display Bold";
    src: url('/public/fonts/webFonts/HelveticaNowDisplayBd/font.woff2') format('woff2'), url('/public/fonts/webFonts/HelveticaNowDisplayBd/font.woff') format('woff');
}

@font-face {
    font-family: "Helvetica Now Display XBold";
    src: url("/public/fonts/Monotype - Helvetica Now Display XBold.otf") format("truetype");
}
