@import url(./Helvetica\ Now\ Display.css);


@font-face {
    font-family: "Helvetica Now Text Regular";
    src: url("/public/fonts/Monotype_HelveticaNowText.otf") format("truetype");
}

@font-face {
    font-family: "Helvetica Now Text Light";
    src: url("/public/fonts/Monotype_HelveticaNowTextLight.otf") format("truetype");
}

@font-face {
    font-family: "Helvetica Now Text Bold";
    src: url("/public/fonts/Monotype_HelveticaNowTextBold.otf") format("truetype");
}

@font-face {
    font-family: "Helvetica Now Text Medium";
    src: url("/public/fonts/Monotype_HelveticaNowTextMedium.otf") format("truetype");
}


@font-face {
    font-family: "Helvetica Now Micro Medium";
    src: url("/public/fonts/Monotype-HelveticaNowMicroMedium.otf") format("truetype");
}




.c-303030 {
    color: #081d34 !important
}

