:root {
  --app-height: 100%;
}

body,
html {
  margin: 0;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100%;
  padding: 0;
  font-family: Helvetica;
  color: #081d34;
  background-color: #ffffff;
  min-height: -webkit-fill-available;
  font-size: 14px;
}

html {
  scroll-behavior: smooth;
}

#root {
  height: 100%
}



@keyframes levitate {
  0% {
    transform: translate(0, -20px);
  }

  100% {
    transform: translate(0, 0);
  }
}


.max-full-screen {
  height: 100%;
  height: 100vh;
  height: 100dvh;

  width: 100%;
  width: 100vw;
}

mark{
  background-color: #a6fff7;
}