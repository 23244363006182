@import url(./Theme/fonts.css);

* {
    opacity: 1;
}
@media (max-width: 600px) {
    .landing-page-block-1 {
        max-width: calc(100% - 20px);
        margin-left: 20px;
        margin-right: 20px;
    }

    .landing-page-block-2 {
        max-width: calc(100% - 20px);
        margin-left: 20px;
        margin-right: 20px;
    }
    .sm-fixed-to-bottom{
        position: fixed !important;
        bottom: 0;
    }
}


.clase-card-list > div {
    border-top:1px solid var(--border-color)
}

.clase-card-list > div:last-child {
    border-bottom:1px solid var(--border-color)
}


.linear-progress-bar-loader {
	display: flex;
	height: 6px;
	width: 100%;
	box-shadow: inset 0px 0px 0px 1px #DDE1E5;
	border-radius: 50px;
	overflow: hidden;
}
	
@keyframes linear-progress-animation {
	0% {width: 0%;}
	20% {width: 10%;}
	40% {width: 30%;}
	50% {width: 60%;}
	100% {width: 90%;}
}
.linear-progress-bar {
	display: flex;
	height: 100%;
	width: 90%;
	background: #0387FF;
	animation: linear-progress-animation var(--animation-duration, 20s) ease-in-out;
}